.zero-state {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--dark-gray);
}
.zero-state .icon-container {
  margin-bottom: calc(var(--basic-margin) * 2);
}
.zero-state .icon-container .icon {
  font-size: 8rem;
  align-items: center;
  transform: rotate(-20deg);
  animation: float 2s infinite;
}
.zero-state h2 {
  font-size: 3rem;
}

@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(10px, 8px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
