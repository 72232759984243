h1 {
  font-size: 1.2rem;
}

.MuiInputBase-root {
  color: inherit !important;
}

@media screen and (min-width: 599px) {
  h1 {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 1023px) {
  h1 {
    font-size: 1.8rem;
  }
}
a {
  text-decoration: none;
}
.paper {
  background: white;
  border-radius: 5px;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
}
.error-message {
  color: var(--error);
  display: flex;
  align-items: center;
}
.error-message svg {
  padding-right: 10px;
  width: 1rem;
  height: 1rem;
}
.warning {
  color: var(--warning);
  background: var(--warning-background);
  display: flex;
  margin: 1rem 0;
  padding: 8px;
  border-radius: 5px;
  align-items: center;
}
.warning .icon {
  padding-right: 10px;
  width: 1rem;
  height: 1rem;
}
.modal-background {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
}
.modal-background .modal {
  background: white;
  max-width: 400px;
  padding: 2rem;
  border-radius: 8px;
}
.modal-background .modal h4 {
  text-align: center;
  margin-bottom: 1rem;
}
.modal-background .modal .modal-actions {
  margin: 1rem -1rem -1rem 0;
  display: flex;
  justify-content: flex-end;
}
