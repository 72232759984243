/* Variables */

/* colors */
:root {
  --primary-color: #00d89e;

  --secondary-color: #0b8fd6;
  --secondary-text-color: #e4f8ff;
  --secondary-color-hover: #61bfff;
  --secondary-text-color-hover: #0062a4;

  --light-green: #d0f7ed;

  --main-background: #f4f9f8;

  --selected: #0b8fd6;
  --light-gray: #efefef;
  --dark-gray: #a0a0a0;

  --error: #ff0700;
  --warning: #ff5c00;
  --warning-background: #ff5c0033;

  /* margin and padding */
  --basic-padding: 1rem;
  --basic-margin: 1rem;

  --animation-slideIn: cubic-bezier(0.07, 0.56, 0.35, 0.96);
}
