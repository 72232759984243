@font-face {
  font-family: 'icomoon';
  src: url('assets/fonts/icomoon.eot?ha1m4x');
  src: url('assets/fonts/icomoon.eot?ha1m4x#iefix') format('embedded-opentype'),
    url('assets/fonts/icomoon.ttf?ha1m4x') format('truetype'),
    url('assets/fonts/icomoon.woff?ha1m4x') format('woff'),
    url('assets/fonts/icomoon.svg?ha1m4x#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tax:before {
  content: '\e900';
}

.icon-arrow-left:before {
  content: '\e901';
}

.icon-arrow-right:before {
  content: '\e902';
}

.icon-bank-account:before {
  content: '\e903';
}

.icon-car:before {
  content: '\e904';
}

.icon-chevron-left:before {
  content: '\e905';
}

.icon-chevron-right:before {
  content: '\e906';
}

.icon-close:before {
  content: '\e907';
}

.icon-cocktail:before {
  content: '\e908';
}

.icon-digid:before {
  content: '\e909';
}

.icon-dutch:before {
  content: '\e90a';
}

.icon-healthcare:before {
  content: '\e90b';
}

.icon-hobbies:before {
  content: '\e90c';
}

.icon-immigration:before {
  content: '\e90d';
}

.icon-insurance:before {
  content: '\e90e';
}

.icon-more-horizontal:before {
  content: '\e90f';
}

.icon-more-vertical:before {
  content: '\e910';
}

.icon-moving-goods:before {
  content: '\e911';
}

.icon-neighborhood-guide:before {
  content: '\e912';
}

.icon-nl-guide:before {
  content: '\e913';
}

.icon-partner:before {
  content: '\e914';
}

.icon-permanent-housing:before {
  content: '\e915';
}

.icon-personal-transport:before {
  content: '\e916';
}

.icon-phone:before {
  content: '\e917';
}

.icon-plus:before {
  content: '\e918';
}

.icon-public-transport:before {
  content: '\e919';
}

.icon-residence-permit:before {
  content: '\e91a';
}

.icon-school:before {
  content: '\e91b';
}

.icon-tb-test:before {
  content: '\e91c';
}

.icon-temporary-housing:before {
  content: '\e91d';
}

.icon-travel-to-nl:before {
  content: '\e91e';
}

.icon-info:before {
  content: '\e91f';
}
