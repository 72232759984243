* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Muli, Arial, Helvetica, sans-serif;
}

strong {
  font-weight: bold;
}