.login {
  height: 100vh;
  display: flex;
  align-items: center;
  background: var(--light-gray);
}
.login .paper {
  max-width: 400px;
  min-width: 300px;
  margin: auto;
  padding: calc(var(--basic-padding) * 2);
}
.login .paper .logo div {
  text-align: center;
  margin: var(--basic-margin);
}
.login .paper .logo div img {
  max-width: 200px;
}
.login .paper section:not(.logo) label {
  display: flex;
  align-items: center;
  width: 100%;
}
.login .paper section:not(.logo) label .input {
  width: 100%;
}
.login .paper section:not(.logo) .error-message {
  color: var(--error);
  text-align: center;
}
.login .paper section:not(.logo) .loader,
.login .paper section:not(.logo) button {
  display: block;
  margin: calc(var(--basic-margin) * 2) auto var(--basic-margin);
  text-align: center;
}
